import React from 'react'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import { selectPack } from '../actions/PackActions'

import calendar from '../assets/ikonki--12px--calendar.png'
import ilosc_zamowien from '../assets/ikonki--12px--ilosc-zamowien.png'
import file_delete from '../assets/ikonki--12px--file-delete.png'
import ban from '../assets/ikonki--12px--ban.png'
import company from '../assets/company.png'
import carrier from '../assets/icons/carrier.png'

const mapStateToProps = state => ({
  show_company: state.config.show_company
})

const mapDispatchToProps = dispatch => ({
  selectPackAction: (id, is_multi, important) => dispatch(selectPack(id, is_multi, important)),
})

class PackListItemComponent extends React.Component {
  selectPack = (event) => {
    const {selectPackAction} = this.props
    const id = parseInt(event.currentTarget.getAttribute('data-id'))
    const is_multi = parseInt(event.currentTarget.getAttribute('data-multi'))
    const important = parseInt(event.currentTarget.getAttribute('data-important'))
    selectPackAction(id, is_multi, important)
  }

  render() {
    const { pack, show_company } = this.props
    return (
      <div onClick={this.selectPack}
           data-id={pack.id}
           data-multi={pack.multipack?'1':'0'}
           data-important={pack.important?'1':'0'}
           data-company_id={pack.company_id}
           className="elem-tile">
        <h5>{pack.name}</h5>
        {pack.logistic_company &&
          <div className="infoRow">
            <label><img src={carrier} alt="logistic_company" />{intl.get('Kurier')}:</label>
            <div>{pack.logistic_company}</div>
          </div>
        }
        {show_company &&
          <div className="infoRow">
            <label><img src={company} alt="company" />{intl.get('Firma')}:</label>
            <div>{pack.company_id[1]}</div>
          </div>
        }
        <div className="infoRow">
          <label><img src={calendar} alt="calendar" />{intl.get('Data')}:</label>
          <div>{pack.create_date}</div>
        </div>
        <div className="infoRow">
          <label><img src={ilosc_zamowien} alt="qty" />{intl.get('Ilość zamówień')}:</label>
          <div>{pack.orders_left} / {pack.order_count}</div>
        </div>
        <div className={'infoRow' + (parseInt(pack.orders_with_error)>0?' text-red':'')}>
          <label><img src={ban} alt="errors" />{intl.get('Błędy')}:</label>
          <div>{pack.orders_with_error}</div>
        </div>
        {parseInt(pack.orders_with_product_shortages)>0 &&
            <div className={'infoRow' + (parseInt(pack.orders_with_product_shortages)>0?' text-red':'')}>
              <label><img src={file_delete} alt="lacks" />{intl.get('Braki')}:</label>
              <div>{pack.orders_with_product_shortages}</div>
            </div>
        }
      </div>
    )
  }
}

const PackListItem = connect(mapStateToProps, mapDispatchToProps)(PackListItemComponent)
export default PackListItem
